import { FC, HTMLProps, MouseEventHandler } from "react";
import styles from "./MainCard.module.scss";
import classNames from "classnames";
import GridContainer from "../../GridContainer/GridContainer";
import Image, { StaticImageData } from "next/image";
import DetailBtn from "../../Buttons/DetailBtn/DetailBtn";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

export type Props = {
  title: string;
  description: string;
  imgSrc: StaticImageData;
  href: string;
  priority?: boolean;
  addclasses?: string[];
} & HTMLProps<HTMLDivElement>;

const MainCard: FC<Props> = (props) => {
  const { t } = useTranslation("common");
  const { imgSrc, addclasses, priority, title, description, href } = props;
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {}
  );

  const router = useRouter();
  const handleClick: MouseEventHandler = (e) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(href, "_blank");
    } else {
      router.push(href);
    }
  };

  return (
    <GridContainer addclasses={[className]} onClick={handleClick}>
      <GridContainer addclasses={[styles["info"]]}>
        <h3 className={styles["title"]}>{title}</h3>
        <p className={styles["desc"]}>{description}</p>
        <DetailBtn title={t("details")} addclasses={[styles["btn"]]} />
      </GridContainer>
      <div className={styles["img-container"]}>
        <Image
          src={imgSrc}
          alt=""
          priority={priority}
          quality={100}
          placeholder="blur"
          sizes="(max-width: 600px) 50vw,
              (max-width: 1920px) 30vw,
              30vw"
          className={styles["img"]}
        />
      </div>
    </GridContainer>
  );
};

export default MainCard;
